// Generated by Framer (450d190)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["yiy2eQuCO", "mriLPUg97", "nueSYZr2T"];

const serializationHash = "framer-IYpih"

const variantClassNames = {mriLPUg97: "framer-v-1wsos2f", nueSYZr2T: "framer-v-5xw567", yiy2eQuCO: "framer-v-1k1to1c"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 1, ease: [0.12, 0.23, 0.5, 1], type: "tween"}

const transformTemplate1 = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {End: "nueSYZr2T", Initial: "yiy2eQuCO", Pulse: "mriLPUg97"}

const getProps = ({color, height, id, width, ...props}) => { return {...props, adPC0uzY1: color ?? props.adPC0uzY1 ?? "var(--token-006fdba6-9690-47f6-9eee-17e66a9c00aa, rgb(216, 80, 64))", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "yiy2eQuCO"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;color?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, adPC0uzY1, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "yiy2eQuCO", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear1oij143 = activeVariantCallback(async (...args) => {
await delay(() => setVariant("mriLPUg97"), 1000)
})

const onAppear13gp9ur = activeVariantCallback(async (...args) => {
await delay(() => setVariant("nueSYZr2T"), 500)
})

const onAppear377838 = activeVariantCallback(async (...args) => {
await delay(() => setVariant("yiy2eQuCO"), 1000)
})

useOnVariantChange(baseVariant, {default: onAppear1oij143, mriLPUg97: onAppear13gp9ur, nueSYZr2T: onAppear377838})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1k1to1c", className, classNames)} data-framer-name={"Initial"} data-highlight layoutDependency={layoutDependency} layoutId={"yiy2eQuCO"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({mriLPUg97: {"data-framer-name": "Pulse"}, nueSYZr2T: {"data-framer-name": "End"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1n59d00"} data-framer-name={"BG Circle"} layoutDependency={layoutDependency} layoutId={"pP6KhvmXb"} style={{backgroundColor: adPC0uzY1, borderBottomLeftRadius: "100%", borderBottomRightRadius: "100%", borderTopLeftRadius: "100%", borderTopRightRadius: "100%", opacity: 0}} transformTemplate={transformTemplate1} variants={{mriLPUg97: {opacity: 0.3}}} {...addPropertyOverrides({mriLPUg97: {transformTemplate: undefined}, nueSYZr2T: {transformTemplate: undefined}}, baseVariant, gestureVariant)}/><motion.div className={"framer-pe4q4n"} data-framer-name={"Circle"} layoutDependency={layoutDependency} layoutId={"WBSAMU4gc"} style={{backgroundColor: adPC0uzY1, borderBottomLeftRadius: "100%", borderBottomRightRadius: "100%", borderTopLeftRadius: "100%", borderTopRightRadius: "100%"}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-IYpih.framer-trejd8, .framer-IYpih .framer-trejd8 { display: block; }", ".framer-IYpih.framer-1k1to1c { height: 18px; overflow: hidden; position: relative; width: 18px; }", ".framer-IYpih .framer-1n59d00 { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 8px); left: 50%; overflow: visible; position: absolute; top: 50%; width: 8px; }", ".framer-IYpih .framer-pe4q4n { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 8px); left: 5px; overflow: visible; position: absolute; top: 5px; width: 8px; }", ".framer-IYpih.framer-v-1wsos2f.framer-1k1to1c, .framer-IYpih.framer-v-5xw567.framer-1k1to1c { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 18px); }", ".framer-IYpih.framer-v-1wsos2f .framer-1n59d00, .framer-IYpih.framer-v-5xw567 .framer-1n59d00 { height: var(--framer-aspect-ratio-supported, 18px); left: 0px; right: 0px; top: 0px; width: unset; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 18
 * @framerIntrinsicWidth 18
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"mriLPUg97":{"layout":["fixed","fixed"]},"nueSYZr2T":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"adPC0uzY1":"color"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerD7mCm6dYl: React.ComponentType<Props> = withCSS(Component, css, "framer-IYpih") as typeof Component;
export default FramerD7mCm6dYl;

FramerD7mCm6dYl.displayName = "Helper/Pulse Indicator";

FramerD7mCm6dYl.defaultProps = {height: 18, width: 18};

addPropertyControls(FramerD7mCm6dYl, {variant: {options: ["yiy2eQuCO", "mriLPUg97", "nueSYZr2T"], optionTitles: ["Initial", "Pulse", "End"], title: "Variant", type: ControlType.Enum}, adPC0uzY1: {defaultValue: "var(--token-006fdba6-9690-47f6-9eee-17e66a9c00aa, rgb(216, 80, 64)) /* {\"name\":\"Orange 55\"} */", title: "Color", type: ControlType.Color}} as any)

addFonts(FramerD7mCm6dYl, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})